import React from "react"
import { Link } from "gatsby";
import { ActiveCampaignInputs, handleActiveCampaignSubmit } from "active-campaign-react"
import { useForm } from "react-hook-form";
import { newsletter, pages } from "../../site-config"

export const NewsletterForm = ({ title, buttonText }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        handleActiveCampaignSubmit(data, newsletter.activeCampaign.companySubDomain, newsletter.activeCampaign.formId)
    }

    if (title && buttonText) {
        return (
            <div className="c-newsletter">
                <div className="container">
                    <div className="row">
                        <div className="c-newsletter__form">
                            <h2 className="c-newsletter__title">{title}</h2>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <fieldset>
                                    <legend className="c-form__legend">Newsletter signup</legend>
                                    <ActiveCampaignInputs formId={newsletter.activeCampaign.formId} />
                                    <div className="c-newsletter__form-fields">
                                        <div className="c-newsletter__form-field">
                                            <div className="c-form__textbox-field">
                                                <label htmlFor="newslettername" className="c-form__label">Name</label>
                                                <input name="newslettername" id="newslettername" placeholder="Name" className={errors.newslettername ? "c-form__textbox error" : "c-form__textbox"} {...register("newslettername", { required: true })} />
                                                {errors.newslettername && <div className="c-form__validation c-form__validation--error"><p>Please enter your name</p></div>}
                                            </div>
                                        </div>
                                        <div className="c-newsletter__form-field">
                                            <div className="c-form__textbox-field">
                                                <label htmlFor="newsletteremail" className="c-form__label">Email</label>
                                                <input name="newsletteremail" id="newsletteremail" placeholder="Email" className={errors.newsletteremail ? "c-form__textbox error" : "c-form__textbox"} {...register("newsletteremail", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} />
                                                {errors.newsletteremail && <div className="c-form__validation c-form__validation--error"><p>Please enter a valid email</p></div>}
                                            </div>
                                        </div>
                                        <div className="c-newsletter__form-field">
                                            <p className="c-newsletter__form-consent">By clicking the 'Join Newsletter' button you're confirming that you agree with our following <Link to={pages.termsConditions}>Terms and Conditions</Link>.</p>
                                        </div>
                                        {buttonText ? 
                                            <div className="c-newsletter__form-field">
                                                <input type="submit" value={buttonText} className="c-newsletter__submit c-btn c-btn--large c-btn--primary" />
                                            </div> : null}
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return null;
}

export default NewsletterForm;