import * as React from "react"
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismic-previews'
import { NewsletterForm } from '../components/forms/newsletter'
import { Layout } from "../components/global/layout"
import { Masthead } from "../components/layout/masthead"

const NewsletterPage = ({ data, pageContext }) => {
    if (!data) 
      return null
  
    const document = data.prismicNewsletter

    return (
        <Layout pageTitle={document.data.title.text} 
                metaTitle={document.data.meta_title.text} 
                metaDescription={document.data.meta_description.text || document.data.summary.text}
                shareImage={document.data.share_image.url}>
            <Masthead subtitle={document.data.subtitle.text} title={document.data.title.text} summary={document.data.summary.text} />
            <NewsletterForm title={document.data.newsletter_title.text} buttonText={document.data.newsletter_buttontext.text} />
        </Layout>
    )
}

export const query = graphql`
  query NewsletterQuery {
    prismicNewsletter {
      _previewable
      data {
        title {
          text
        }
        summary {
          text
        }
        subtitle {
          text
        }
        newsletter_title {
          text
        }
        newsletter_buttontext {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        share_image {
          url
        }
      }
    }
  }  
`

export default withPrismicPreview(NewsletterPage, repositoryConfigs)

